<template>
  <div class="home">
    <Header />
    <h3>Welcome to date night!</h3>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
export default {
  name: 'Home',
  components: {
    Header
  }
}
</script>
