<template>
  <header>
    <Navbar links="[{to: '/home', component: 'Home', text: 'Home'},{to:'/about/rex',component: 'About',text: 'Rex'}, {to: '/about/bear',component: 'About', text: 'Bear'}]" />
  </header>
</template>
<script>
import {Navbar} from './Navbar'
export default {
  name: 'Header',
  components: {
    Navbar
  }
}
</script>