<template>
  <div id="nav" class="p-14">
    <router-link v-for="(link, index) in links" :bind="to" :key="index">{{link.text}}</router-link>

  </div>
</template>

<script>
export default {
  data() {
    return{
      links: this.props.links
    }
  }
}
</script>