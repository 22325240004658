<template>
  <Header />
  <router-view/>
</template>

<style>
</style>
<script>
import Header from '@/components/Header.vue'
export default {
  components: {
    Header
  }
}
</script>
